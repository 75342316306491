table.responsive-table {
    box-sizing: border-box;
    margin: 0;
    padding: 17px 0 0 0;
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: separate;
    background-color: transparent;
    text-align: left;
    border-top: 1px solid var(--theme--rule--color);
    tr {
        th,td {
            position: relative;
            z-index: 1;
            vertical-align: top;
        }
        th {
            background: transparent;
            margin: 0;
            padding: 10px 15px 10px 0;
            small {
                font-family: $font-family-base;
                color: var(--theme--color--subtle);
                text-transform: none;
            }
        }
        td {
            background: transparent;
            margin: 0;
            padding: 10px 15px 10px 0;
            min-height: 45px;
            strong {
                color: var(--theme--color--emphasis);
            }
        }
    }
    thead {
        th {
            background: transparent;
            color: var(--theme--color--subtle);
            padding-top: 0;
            font-weight: normal;
            &.emphasis {
                color: var(--theme--color--emphasis);
            }
        }
    }
    tbody {
        th {
            font-family: $font-alt-heading;
            small {
                font-family: $font-family-base;
            }
        }
        th,td {
            border-top: 1px solid var(--theme--rule--color);
        }
        td {
            &.emphasis {
                background-color: transparent;
            }
        }
    }
    tfoot {
        tr {
            td {
                border-top: 1px solid var(--theme--rule--color);
                padding: 20px 0;
            }
        }
    }

    &.opening-times-week-table {
        abbr {
            text-decoration: none;
            cursor: default;
        }
    }
    
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border-top: none;
        thead, tbody, tfoot {
            display: block;
            width: 100%;
            tr {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                th, td {
                    display: block;
                    flex-basis: 0;
                    flex-grow: 1;
                }
            }
        }
        thead {
            /* screen readers only */
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            pointer-events: none;
        }
        tbody {
            tr {
                th {
                    background: transparent;
                    color: var(--theme--color--emphasis);
                    font-size: 1.1rem;
                    padding-top: 20px;
                    padding-bottom: 5px;
                    small {
                        color: var(--theme--color--subtle);
                    }
                }
                td {
                    position: relative;
                    z-index: 2;
                    min-width: 100%;
                    padding: 5px 15px 5px 0;
                    border: 0;
                    min-height: 1rem;
                    &:last-child {
                        padding-bottom: 20px;
                    }
                    &:before {
                        display: block;
                        box-sizing: border-box;
                        float: left;
                        min-width: 50%;
                        max-width: 50%;
                        min-height: 100%;
                        content: attr(data-label);
                        color: $body-color;
                        margin: -5px 22px -5px 0;
                        padding: 5px 15px 5px 0;
                    }
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 50%;
                        bottom: 0;
                        background: transparent;
                        z-index: -1;
                    }
                }
            }
        }
        tfoot {
            display: block;
            width: 100%;
            tr {
                td {
                    min-width: 100%;
                }
            }
        }

        &.opening-times {
            tbody {
                tr {
                    &.closed-day td {
                        min-width: 100%;
                    }
                }
            }
        }

        &.mobile-sticky-tfoot {
            position: relative;
            tfoot {
                position: sticky;
                bottom: 0;
                left: 0;
                z-index: 2;
                tr td {
                    padding: 30px 0;
                }
            }
        }
    }
}
