.logo-listing {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    li {
        list-style: none;
        margin: 0;
        padding: 0;
        width: calc((100% - 20px) / 2);
        min-height: 100%;
        @include media-breakpoint-up(sm) {
            width: 100%;
            max-width: 150px;
        }
        a {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            background-color: white;
            border: 1px solid var(--theme--rule--color);
            padding: 14px;
            transition: border-color 300ms linear;
            img {
                transform: scale(1);
                transition: transform 300ms ease-in-out;
            }
            &:hover, &:focus {
                border: 1px solid var(--theme--link--color--hover);
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}
