@each $key, $value in $panel-flash-bg-colors {
    .flash--bg-color--#{''+$key+''} {
        --panel-flash--bg-color: #{$value};
    }
}

.panel {
    box-shadow: 0 2px 6px rgba(black, 0.3);
    & > *:last-child {
        margin-bottom: 0;
    }
    &--cta {
        text-align: end;
        font-family: $font-alt-heading;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        text-transform: uppercase;
        font-size: 1rem;
        margin-bottom: 0;
    }
}

.wide-image-text-panel {
    display: block;
    .wide-image-text-panel--image {
        display: block;
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        .wide-image-text-panel--image {
            position: relative;
            flex: 0 0 auto;
            min-height: 100%;
            width: 50%;
            max-width: 300px;
            margin: 0;
            &:before { // minimum ratio 16:9
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 56.25%;
            }
            .img-scale-wrapper, img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .wide-image-text-panel--content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-height: 100%;
            justify-content: center;
            align-items: flex-start;
            .wide-image-text-panel--content--inner {
                width: 100%;
            }

        }
        // image right
        &.wide-image-text-panel--image-right {
            .wide-image-text-panel--image {
                order: 2;
            }
            .wide-image-text-panel--content {
                order: 1;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .wide-image-text-panel--image {
            max-width: 400px;
        }
    }
}

.offset-square-image-panel {
    --offset: #{$grid-gutter-width};
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    width: 100%;
    padding-top: calc((100% - var(--offset)) / 2);
    padding-bottom: var(--offset);
    aspect-ratio: 1;
    &--img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: calc(100% - var(--offset));
        background-color: $color--darkest-grey;
        img {
            display: block;
            width: 100%;
        }
    }
    &--content {
        position: relative;
        margin-bottom: calc(-1 * var(--offset));
        z-index: 2;
        width: calc(100% - var(--offset));
    }
}

.map-panel {
    width: 100%;

    .map-mask {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 100%;
        overflow: hidden;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding-bottom: 56.25%;
        }

        iframe, picture, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: none;
            z-index: 0;
            border: 0;
        }
    }
}

.staff-member {
    --theme--color--emphasis: white;
    aspect-ratio: 3/4;
    position: relative;
    img {
        display: block;
    }
    &--details {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(black, 0.4);
        backdrop-filter: blur(10px);
        color: white;
        padding: 10px;
        h3 {
            margin-bottom: 0.1rem;
        }
        p {
            margin: 0;
        }
    }
}

.package-upsell {
    gap: 10px;
    flex-direction: column;
    p {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 20px;
        p {
            flex: 1 1 auto;
            &.package-upsell--cta {
                margin-left: auto;
                flex: 0 1 auto;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .btn {
            white-space: nowrap;
        }
    }
}

.package-upsell-panel {
    display: flex;
    flex-direction: column;
    position: relative;
    background: $brand--dark-blue url("../../img/package-upsell-panel/panel-bg-decor.jpg") center center no-repeat;
    background-size: 100% auto;
    color: white;
    text-align: center;
    p {
        margin: 0;
    }
    .package-upsell-panel--image {
        padding: 0 .5rem;
        img {
            max-width: 280px;
            margin-top: -1rem;
        }
    }
    .package-upsell-panel--content {
        padding: 1rem;
    }
    .package-upsell-panel--cta {
        padding: .5rem 1rem 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        text-align: left;
        .package-upsell-panel--image {
            padding: 0;
            width: 200px;
            flex: 0 0 auto;
            img {
                width: calc(100% + 1rem);
                margin: -1rem 0 -1rem -2rem;
                object-fit: contain;
            }
        }
        .package-upsell-panel--content {
            padding: 1rem 0;
            flex: 1 1 auto;
        }
        .package-upsell-panel--cta {
            padding: 1rem;
            flex: 1 1 auto;
            .btn {
                text-align: left;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .package-upsell-panel--image {
            width: 250px;
        }
    }
}
