﻿.btn-link[data-toggle="collapse"] {
    .fa-angle-down, .fa-arrow-down {
        transform: rotate(0);
        transition: transform 300ms ease-in-out;
        will-change: transform;
    }
    &[aria-expanded="true"] {
        .fa-angle-down, .fa-arrow-down {
            transform: rotate(180deg);
        }
    }
}

.expander {
    &.card {
        border: 1px solid var(--theme--rule--color);
        .card-header, .card-body, .card-footer {
            background-color: transparent;
            padding: 15px;
            border: 0;
        }
        .card-body {
            padding: 15px;
        }
        .accordion &:not(:last-of-type) {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    .card-header {
        button {
            all: unset;
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding-right: 15px;
            position: relative;
            text-align: inherit;
            color: var(--theme--link--color);
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: underline;
            }
            .indicator {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                font-size: 1rem;
                line-height: 1rem;
                transform: translateY(-50%) rotate(0);
                transition: transform 300ms ease-in-out;
                will-change: transform;
            }
            &[aria-expanded="true"] .indicator {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    .card-body p:last-child {
        margin-bottom: 0;
    }

}
