#sticky-booking-widget {
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: 100%;
    .sticky-booking-widget--inner {
        padding: 10px 0;
        background-color: $brand--white;
        box-shadow: 0 0 8px rgba(black, 0.4);
        will-change: transform;
        transform-origin: 0 100%;
        transform: translate3d(0,100%,0);
        transition: transform 1s ease-out;
        pointer-events: none;
        nav {
            ul {
                display: flex;
                justify-content: space-around;
                gap: 5px;
                list-style: none;
                margin: 0;
                padding: 0;
                @include media-breakpoint-up(md) {
                    gap: 10px;
                }
                li {
                    display: inline-block;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    .btn {
                        padding: 0.4rem 0.5rem 0.2rem;
                        font-size: 0.7rem;
                        &:before {
                            display: none;
                            content: none;
                        }
                        svg {
                            display: none;
                        }
                        @media(min-width: 400px) {
                            padding: $btn-theme--sm--padding-top $btn-theme--sm--padding-x $btn-theme--sm--padding-bottom;
                            font-size: $btn-font-size-sm;
                        }
                        @include media-breakpoint-up(md) {
                            padding: $btn-theme--padding-top $btn-theme--padding-x $btn-theme--padding-bottom;
                            font-size: 16px;
                            svg {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    // shown in page
    &.show {
        .sticky-booking-widget--inner {
            transform: translate3d(0,0,0);
            pointer-events: all;
        }
    }
}
