﻿// ********************
// **  Site colours  **
// ********************

$brand--dark-blue:      rgb(28,53,85);
$brand--red:            rgb(215, 40, 45);
$brand--white:          rgb(255,255,255);

$color--lightest-grey: 	rgb(233, 235, 236);
$color--lighter-grey: 	rgb(193,198,200);
$color--mid-grey: 	    rgb(132, 146, 156);
$color--dark-grey: 	    rgb(91,103,112);
$color--darkest-grey: 	rgb(45,41,38);

$color--bluish-grey: 	rgb(231,236,242);
$color--blue: 	        rgb(11,85,175);

$green:                 rgb(19, 184, 0);

// set fundamental bootstrap colour variables

$primary:                       $brand--red;
$secondary:                     $color--mid-grey;
$light:                         $color--lightest-grey;
$dark:                          $color--darkest-grey;

$body-bg:                       $brand--white;
$body-color:                    $dark;

$link-color:                    $primary;
$link-decoration:               none;
$link-hover-color:              $color--blue;
$link-hover-decoration:         underline;


// ***********************
// **  top utility bar  **
// ***********************

$top-utility-bar--height--mobile:                   40px;
$top-utility-bar--height--desktop:                  40px;


// *******************
// **  main header  **
// *******************

$header--height--mobile:                            75px;
$header--height--desktop:                           75px;

// *****************
// **  Hero area  **
// *****************

$hero--landscape--min-height:            400px;
$hero--mobile--min-height:               360px;
$hero--desktop--min-height:              400px;
$hero--desktop--max-height:              600px;

// ***********************
// **  img-loader-icon  **
// ***********************

$img-loader-icon--width: 3rem;
$img-loader-icon--height: 3rem;
$img-loader-icon--background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 51'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m79.93.1c-.07.05-.74.55-1.5,1.12-13.19,9.71-22.03,20.63-23.94,29.57-.33,1.55-.36,4.15-.06,5.26.73,2.68,2.36,4.27,4.92,4.79,3.23.65,7.93-.94,12.26-4.15.48-.36.88-.61.88-.56,0,.17-1.29,2.37-2.06,3.54-2.34,3.47-5,6.31-8.16,8.67-1.27.94-3.32,2.03-4.35,2.28-3.46.86-12.84.2-17.98-1.28-2.04-.58-2.88-1.21-5.61-4.19-5.37-5.85-7.88-8.35-11.57-11.52-5.77-4.94-12.57-9.53-20.57-13.87C.96,19.09-.03,18.54,0,18.54s.76.25,1.61.56c6.72,2.44,12.24,4.78,22.48,9.51,7.46,3.44,9.69,4.42,10.93,4.79l.5.14,2.39-2.41c11.74-11.88,27.33-23.64,39.83-30.05,1.98-1.03,2.47-1.24,2.2-.99Zm-45.4,14.92c2.37.77,4.35,2.81,4.94,5.14.28,1.04.18,2.99-.17,4.02-.78,2.2-2.88,4.07-5.3,4.7-1.14.3-3.05.26-4.15-.07-2.54-.77-4.33-2.45-5.15-4.81-.41-1.21-.44-2.91-.07-4.12.61-1.94,1.99-3.5,3.92-4.45,1.29-.63,2.11-.79,3.72-.75,1.05.03,1.52.1,2.26.35Z'/%3E%3C/svg%3E") no-repeat;

// **********************
// **  custom buttons  **
// **********************

$btn-theme--padding-x: 0.75rem;
$btn-theme--padding-top: 0.65rem;
$btn-theme--padding-bottom: 0.65rem;
$btn-theme--lg--padding-x: 1rem;
$btn-theme--lg--padding-top: 1rem;
$btn-theme--lg--padding-bottom: 1rem;
$btn-theme--sm--padding-x: 0.75rem;
$btn-theme--sm--padding-top: 0.5rem;
$btn-theme--sm--padding-bottom: 0.5rem;

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $color--darkest-grey;

// **************************
// **  Responsive Nav Bar  **
// **************************

$nav-bar--bg-color: white;
$nav-bar--item--color: $color--dark-grey;
$nav-bar--item--decoration: none;
$nav-bar--item--hover-bg-color: white;
$nav-bar--item--hover-color: $link-hover-color;
$nav-bar--item--hover-decoration: underline;
$nav-bar--item--active-color: $primary;
$nav-bar--item--active-bg-color: $color--lightest-grey;

// **************
// **  Panels  **
// **************

$panel-flash-bg-colors: (
    red: $brand--red,
    black: rgb(0,0,0),
    pink: rgb(233, 4, 141),
    blue: $color--blue,
    green: $green
);



// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************

$component-active-bg:           $primary;
$component-active-color:        white !important;

// Fonts

$font-family-sans-serif:       'Flama Book', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-strong:                  'Flama Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-family-base:             $font-family-sans-serif;

$font-heading:                'United Italic Semi Condensed Medium', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-heading-strong:         'United Italic Semi Condensed Black', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-alt-heading:            'United Italic Regular Black', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

$font-size-base:              1.125rem; // Assumes the browser default, typically `16px`
$font-weight-base:            400;
$line-height-base:            1.4;

$lead-font-size:              $font-size-base * 1.05;
$lead-font-weight:            400;

$paragraph-margin-bottom:     1.25rem;

$headings-font-family:        $font-heading;
$headings-font-weight:        400;
$headings-line-height:        1.1;

$h1-font-size:                $font-size-base * 2.6;
$h2-font-size:                $font-size-base * 2.0;
$h3-font-size:                $font-size-base * 1.7;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                1rem;

$display1-size:               4rem;
$display2-size:               3.6rem;
$display3-size:               3.2rem;
$display4-size:               2.8rem;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// Forms + Buttons

$border-radius:                   0;
$border-radius-lg:                0;
$border-radius-sm:                0;

$btn-border-radius:           5px;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

$form-select-border-radius:       $btn-border-radius;
$form-select-border-radius-sm:    $btn-border-radius;
$form-select-border-radius-lg:    $btn-border-radius;

$form-check-input-checked-color:        white;

$form-range-thumb-width:         1.4rem;
$form-range-thumb-height:        $form-range-thumb-width;
$form-range-thumb-active-bg:     $link-hover-color;
