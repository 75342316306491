.social-reviews {
    --img-height: 65px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
        --img-height: 75px;
    }

    .social-reviews--heading {
        display: flex;
        align-items: stretch;
        gap: 15px;
        .logo {
            width: auto;
            height: var(--img-height);
            flex: 0 0 auto;
        }
        .social-reviews--heading--content {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100%;
            h2 {
                margin-bottom: 0;
                transform: translateY(-0.15em);
            }
            p.rating {
                margin: 0;
                font-family: $font-alt-heading;
            }
        }
    }

    .social-reviews--listing {
        li {
            opacity: 0;
            transform: translate3d(-30px,30px,0);
            will-change: transform;
            transition: opacity 500ms linear, transform 1s ease-out;
            transition-delay: 500ms;
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            blockquote.review {
                display: block;
                margin: 0;
                padding: 0;
                .review--panel {
                    position: relative;
                    z-index: 2;
                    background: white url('../../img/google-logo.svg') no-repeat;
                    background-position: top 20px right 20px;
                    background-size: 25px;
                    color: $dark;
                    padding: 20px 20px 25px;
                    .review--star-rating {
                        color: rgb(250,196,0);
                        font-size: 1.2rem;
                        line-height: 0.95;
                        svg {
                            display: inline-block;
                            height: 1em;
                            width: 1.0526em;
                        }
                    }
                    .review--quote {
                        margin: 0;
                    }
                }
                footer {
                    position: relative;
                    z-index: 1;
                    display: block;
                    margin: 0;
                    cite {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        width: 100%;
                        font-style: normal;
                        text-transform: uppercase;
                        color: white;
                        strong {
                            display: block;
                            font-family: $font-alt-heading;
                            font-weight: $headings-font-weight;
                            line-height: $headings-line-height;
                            padding-right: 15px;
                            opacity: 0;
                            will-change: opacity;
                            transition: opacity 500ms linear;
                            transition-delay: 2000ms;
                        }
                        br {
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            border-top: 36px solid white;
                            border-right: 30px solid transparent;
                            margin-right: auto;
                            transform: translate3d(0,-100%,0);
                            will-change: transform;
                            transition: transform 1s ease-out;
                            transition-delay: 1s;
                        }
                        .date {
                            display: block;
                            font-family: $headings-font-family;
                            font-weight: $headings-font-weight;
                            line-height: $headings-line-height;
                            opacity: 0;
                            will-change: opacity;
                            transition: opacity 500ms linear;
                            transition-delay: 2500ms;
                        }
                    }
                }
            }
        }
        &.facebook {
            li {
                transform: translate3d(30px,30px,0);
                blockquote.review {
                    .review--panel {
                        background-image: url('../../img/facebook-logo.svg');
                    }
                    footer {
                        strong {
                            order: 3;
                            padding-right: 0;
                            padding-left: 15px;
                        }
                        br {
                            order: 2;
                            border-right: 0;
                            border-left: 30px solid transparent;
                            margin-right: 0;
                        }
                        .date {
                            order: 1;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
        li.hunt-in {
            opacity: 1;
            transform: translate3d(0,0,0);
            blockquote.review {
                footer {
                    strong {
                        opacity: 1;
                    }
                    br {
                        transform: translate3d(0,0,0);
                    }
                    .date {
                        opacity: 1;
                    }
                }
            }
        }
    }

}
