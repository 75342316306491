﻿.responsive-nav-bar {
    --side-btn--width: 30px;
    --side-btn--margin: 5px;
    position: relative;
    width: 100%;
    background-color: $nav-bar--bg-color;
    color: white;
    padding: 0 5px;
    will-change: transform;
    user-select: none;
    box-shadow: 0 2px 6px rgba(black, 0.3);

    &--button {
        display: none;
        position: absolute;
        top: 0;
        padding: 0;
        z-index: 2;
        height: 100%;
        width: var(--side-btn--width);
        justify-content: center;
        align-items: center;
        border-radius: 0;
        will-change: transform;
        outline: 0;
        &.previous {
            left: 0;
        }
        &.next {
            right: 0;
        }
    }
    .responsive-nav-bar--ul-mask {
        position: relative;
        overflow: hidden;
        padding: 0;
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, lg);
        }
        @include media-breakpoint-up(xl) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, xl);
        }
        @include media-breakpoint-up(xxl) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, xxl);
        }
        ul {
            min-width: 100%;
            width: intrinsic;           /* Safari/WebKit uses a non-standard name */
            width: -moz-max-content;    /* Firefox/Gecko */
            width: -webkit-max-content; /* Chrome */
            width: -ms-max-content; /* IE */
            transition: transform 300ms ease-in-out;
            will-change: transform;
            padding: 5px 0;
            gap: 10px;
            .nav-link {
                --theme--link--color: #{$nav-bar--item--color};
                --theme--link-color--hover: #{$nav-bar--item--hover-color};
                position: relative;
                z-index: 1;
                background-color: $nav-bar--bg-color;
                color: $nav-bar--item--color;
                text-decoration: $nav-bar--item--decoration;
                transition: background-color 500ms linear;
                border-radius: $btn-border-radius;
                padding: $btn-theme--padding-top $btn-theme--padding-x $btn-theme--padding-bottom;
                outline: 0;
                font-family: $font-alt-heading;
                font-weight: $headings-font-weight;
                line-height: $headings-line-height;
                font-size: 16px;
                text-transform: uppercase;
                &:before {
                    content: '';
                    display: inline-block;
                    height: 16px;
                }
                &:not(.active) {
                    &:hover, &:focus {
                        transition-duration: 200ms;
                        background-color: $nav-bar--item--hover-bg-color;
                        color: $nav-bar--item--hover-color;
                        text-decoration: $nav-bar--item--hover-decoration;
                    }
                }
                &.active {
                    --link-color: #{$nav-bar--item--active-color};
                    --link-color-hover: #{$nav-bar--item--active-color};
                    color: $nav-bar--item--active-color !important;
                    background-color: $nav-bar--item--active-bg-color;
                    transition-duration: 200ms;
                    cursor: default;
                    &:hover, &:focus {
                        color: $nav-bar--item--active-color;
                        text-decoration: $nav-bar--item--decoration;
                    }
                }
            }
        }
    }
    &.scroll {
        padding-left: calc(var(--side-btn--width) + var(--side-btn--margin));
        padding-right: calc(var(--side-btn--width) + var(--side-btn--margin));
        .responsive-nav-bar--button {
            display: flex;
            i, svg {
                margin: 0 !important;
            }
        }
    }
    // sticky positioning
    @supports(position: sticky) {
        &.responsive-nav-bar--sticky {
            position: sticky;
            z-index: 8;
            top: calc(var(--top-utility-bar--height) + var(--header--height));
            transition: top 300ms ease-in-out;
            will-change: transform;
            .header-hidden & {
                top: 0;
            }
        }
    }
}
