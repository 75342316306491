@font-face {
    font-display: fallback;
    font-family:"Flama Book";
    src: url('../../fonts/Flama-Book.woff2') format('woff2'), url('../../fonts/Flama-Book.woff') format('woff');
}
@font-face {
    font-display: fallback;
    font-family:"Flama Bold";
    src: url('../../fonts/Flama-SemiBold.woff2') format('woff2'), url('../../fonts/Flama-SemiBold.woff') format('woff');
}

@font-face {
    font-display: fallback;
    font-family:"United Italic Regular Black";
    src: url('../../fonts/UnitedItalicRgBk.woff2') format('woff2'), url('../../fonts/UnitedItalicRgBk.woff') format('woff');
}
@font-face {
    font-display: fallback;
    font-family:"United Italic Semi Condensed Medium";
    src: url('../../fonts/UnitedItalicSmCdMd.woff2') format('woff2'), url('../../fonts/UnitedItalicSmCdMd.woff') format('woff');
}
@font-face {
    font-display: fallback;
    font-family:"United Italic Semi Condensed Black";
    src: url('../../fonts/UnitedItalicSmCdBk.woff2') format('woff2'), url('../../fonts/UnitedItalicSmCdBk.woff') format('woff');
}
