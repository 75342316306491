// themed list global styles

ul.themed-list, ol.themed-list, ul.icon-list, ul.tick-list {
    display: block;
    list-style: none;
    padding: 0;
    li {
        list-style: none;
        display: block;
        position: relative;
        margin: 0;
        padding: 0 0 0 1.75em;
        &:before {
            display: block;
            content: '';
            position: absolute;
        }
    }
}


// unordered list

ul.themed-list {
    li {
        &:before {
            top: 0.5em;
            left: 0;
            width: .5em;
            height: .5em;
            border-radius: 0.25em;
            background-color: var(--theme--decor--color);
        }
    }
}

// ordered list

ol.themed-list {
    counter-reset: list-number;
    li {
        counter-increment: list-number;
        &:before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: counter(list-number);
            top: 0.25em;
            left: 0;
            width: 1.6em;
            height: 1.6em;
            border-radius: 50%;
            background-color: var(--theme--decor--color);
            color: var(--theme--background-color);
            font-family: $font-strong;
            font-weight: normal;
            font-size: 0.7em;
        }
    }
}

// icon list

ul.icon-list {
    text-align: left !important;
    li {
        &:before {
            display: none;
            content: none;
        }
        i, svg {
            position: absolute;
            top: 0.2em;
            left: -0.1em;
            color: var(--theme--decor--color);
        }
    }
}

// tick-list (purely class based)

ul.tick-list {
    text-align: left !important;
    li {
        &:before {
            top: 0.2em;
            left: -0.1em;
            width: 1em;
            height: 1em;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23d7282d' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            @supports(mask-image: url(test.svg)) {
                background: var(--theme--decor--color);
                mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23d7282d' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
                mask-size: contain;
                mask-repeat: no-repeat;
            }
        }
    }
}
