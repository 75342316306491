


// light themes

.bg--theme--white, .bg--theme--bluish-grey, .bg--theme--translucent-white {
    @include on-light-theme-vars;
}
.bg--theme--white {
    --theme--background-color: #{$brand--white};
    background: var(--theme--background-color);
}
.bg--theme--bluish-grey {
    --theme--background-color: #{$color--bluish-grey};
    background: var(--theme--background-color);
}
.bg--theme--translucent-white {
    --theme--background-color: #{$brand--white};
    background-color: rgba(white, 0.4) !important;
    backdrop-filter: blur(10px);
}

// dark themes

.bg--theme--darkest-grey, .bg--theme--red, .bg--theme--blue-red-gradient {
    @include on-dark-theme-vars;
}
.bg--theme--darkest-grey {
    --theme--background-color: #{$color--darkest-grey};
    background: var(--theme--background-color);
}
.bg--theme--red {
    --theme--background-color: #{$brand--red};
    background: var(--theme--background-color);
}
.bg--theme--blue-red-gradient {
    --theme--background-color: #{$color--darkest-grey};
    background: radial-gradient(ellipse at bottom right, rgb(107,21,24), $brand--dark-blue);
}

// custom backgrounds

.bg--transparent {
    background-color: transparent !important;
}

// XMAS styling modifications

body.xmas-theme {
    &, & .section-wrapper.bg--theme--white, & .section-wrapper.bg--theme--bluish-grey, & .section-wrapper.bg--theme--translucent-white {
        position: relative;
        z-index: 2;
        background: transparent !important;
        backdrop-filter: none !important;
    }
}

body.xmas-theme .xmas-theme--bg {
    position: absolute;
    inset: 0 0 0 0;
    z-index: -1;
    clip-path: inset(0 0 0 0);
    &:before {
        display: block;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../img/xmas-theme/frost-tile--left--mobile.png), url(../../img/xmas-theme/frost-tile--right--mobile.png), var(--theme--background-color);
        background-size: 75px 210px, 75px 210px, 100% 100%;
        background-position: 0 0, 100% 0, 0 0;
        background-repeat: repeat-y, repeat-y, no-repeat;
        @include media-breakpoint-up(lg) {
            background: url(../../img/xmas-theme/frost-tile--left--desktop.png), url(../../img/xmas-theme/frost-tile--right--desktop.png), var(--theme--background-color);
            background-size: 220px 420px, 220px 420px, 100% 100%;
            background-position: -100px 0, calc(100% + 100px) 0, 0 0;
            background-repeat: repeat-y, repeat-y, no-repeat;
        }
        @include media-breakpoint-up(xl) {
            background-position: -50px 0, calc(100% + 50px) 0, 0 0;
        }
        @include media-breakpoint-up(xxl) {
            background-position: 0 0, 100% 0, 0 0;
        }
    }
}
body.xmas-theme .section-wrapper.bg--theme--translucent-white .xmas-theme--bg {
    --theme--background-color: #{rgba(white, 0.4)};
    &:before {
        backdrop-filter: blur(10px);
    }
}
