// signpost list

ul.signpost-list {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    li {
        margin: 0;
        width: 100%;
        max-width: 300px;
        a {
            --theme--color--emphasis: #{$color--dark-grey};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 5px;
            padding: 20px 10px;
            gap: 10px;
            & > *:last-child {
                margin-bottom: 0 !important;
            }
            .signpost-list--icon {
                font-size: 32px;
                line-height: 1;
                text-align: center;
            }
            .signpost-list--text {
                text-align: center;
            }
            &:hover, &:focus {
                text-decoration: none;
                .signpost-list--icon, .signpost-list--text {
                    color: var(--theme--link--color--hover);
                }
            }
        }
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        li {
            margin: 0;
            width: calc(50% - 15px);
            flex: 0 1 auto;
            min-height: 100%;
            a {
                min-height: 100%;
            }
        }
    }
    @include media-breakpoint-up(md) {
        li {
            width: calc((100% - 90px) / 4);
        }
    }
}

// visual listing

.visual-listing {
    &--item {
        display: block;
        background-color: white;
        box-shadow: 0 2px 6px rgba(black, 0.3);
        margin-bottom: $grid-gutter-width;

        &--image {
            img {
                aspect-ratio: 16/9;
            }
        }

        &--content {
            color: $dark;

            h3 {
                font-family: $font-strong;
                font-weight: normal;
                font-size: 1.4rem;
                text-transform: none;
                color: var(--theme--link--color);
                text-transform: uppercase;
            }

            & > *:last-child {
                margin-bottom: 0;
            }

            &--cta {
                min-height: 42px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 66.88 42.81'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23DFE2E3;%7D%3C/style%3E%3C/defs%3E%3Cpath id='Path_128' data-name='Path 128' class='cls-1' d='M0,15.8C22.54,28.11,27.21,38.28,32.22,41c5.45,3,12.54,1.6,16.64.11a19,19,0,0,0,11.6-10.44C43.36,44.15,34.74,23.56,66.88,0,52.68,6.8,36.64,21,29.77,28.64,27.06,28.18,13.13,20.38,0,15.8'/%3E%3Cpath id='Path_129' data-name='Path 129' class='cls-1' d='M33.79,18.72a6.38,6.38,0,1,1-6.73-6.14h.37a6.27,6.27,0,0,1,6.36,6.15'/%3E%3C/svg%3E");
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: 65px auto;
            }
        }

        &.visual-listing--item--unavailable {
            cursor: not-allowed;

            .visual-listing--item--image {
                opacity: 0.5;

                img {
                    transform: scale(1.005) !important;
                }
            }

            .visual-listing--item--content {
                opacity: 0.5;

                h3 {
                    color: $dark;
                    text-transform: uppercase;

                    .unavailable {
                        font-family: $font-family-base;
                        font-weight: normal;
                        text-transform: none;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    a.visual-listing--item {
        &:hover, &:focus {
            text-decoration: none;

            .visual-listing--item--content {
                color: $dark;

                h3 {
                    color: var(--theme--link--color--hover);
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }
        }
    }
}

// taglist

ul.taglist {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            display: inline-flex;
            align-items: center;
            border: 1px solid var(--theme--color--subtle);
            background-color: transparent;
            color: var(--theme--color--subtle);
            padding: 2px 8px;
            min-height: 16px;
            border-radius: $btn-border-radius;
            font-family: $font-strong;
            font-weight: normal;
            font-size: 10px;
            text-transform: uppercase;
            &:hover, :focus {
                border-color: $link-hover-color;
                background-color: $link-hover-color;
                color: white;
                text-decoration: none;
            }
        }
    }
}

// blog-listing

.blog-listing {
    ul.taglist {
        margin: 0 0 10px;
        justify-content: flex-end;
        @include media-breakpoint-up(md) {
            float: right;
            margin: 0 0 15px 15px;
            max-width: 50%;
        }
        @include media-breakpoint-up(lg) {
            max-width: 33%;
        }
        @include media-breakpoint-up(xl) {
            max-width: 25%;
        }
    }
}

.listing--filter-panel {
    background-color: rgba(white, 0.4);
    border: 1px solid rgba(white, 0.4);
    button[data-bs-toggle="collapse"] {
        i, svg {
            margin-left: 0.5em;
            font-size: 16px;
            will-change: transform;
            transition: transform 300ms ease-in-out;
        }
        &[aria-expanded="true"] {
            i, svg {
                transform: rotate(180deg);
            }
        }
    }
    select.btn.btn-outline-secondary.btn-theme {
        background-color: white;
        &:hover, &:focus {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
        }
    }
    ul.filter-tags-list {
        display: inline-flex;
        gap: 10px;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li.filter-tag {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
            input[type="checkbox"] {
                position: absolute;
                clip: rect(0,0,0,0);
                pointer-events: none;
            }
            input[type="checkbox"] + label {
                position: relative;
                background-color: white;
                &:before {
                    display: block;
                    content: '';
                    width: 0.9em;
                    height: 1em;
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' role='img' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                    background-size: 100%;
                    position: absolute;
                    top: 0.65rem;
                    left: 0.2em;
                    opacity: 0;
                    transform: scale(0.5) rotate(-3deg);
                    transition: transform 300ms ease-in-out, opacity 300ms linear;
                    will-change: transform;
                }
                span {
                    display: inline-block;
                    will-change: transform;
                    transition: transform 300ms ease-in-out;
                }
            }
            input[type="checkbox"]:checked + label {
                background-color: var(--theme--btn--primary--bg-color);
                border-color: var(--theme--btn--primary--border-color);
                color: var(--theme--btn--primary--color);
                &:before {
                    opacity: 0.7;
                    transform: scale(1) rotate(-3deg);
                }
                span {
                    transform: translate3d(0.5em, 0, 0);
                }
            }
        }
    }
}

.bg--theme--bluish-grey .pagination {
    .page-item {
        .page-link {
            font-size: 1rem;
            color: $color--dark-grey !important;
            background-color: rgba(white, 0.4);
            border-color: rgba(white, 0.4);
            &:hover, &:focus {
                color: white !important;
                background-color: $color--blue;
                border-color: $color--blue;
            }
        }
        &.active {
            .page-link {
                color: white !important;
                background-color: $brand--red;
                border-color: $brand--red;
                &:hover, &:focus {
                    color: white !important;
                    background-color: $brand--red;
                    border-color: $brand--red;
                }
            }
        }
    }
}
