// This is needed for lazy sizes to autocompute width

img[data-sizes="auto"] {
    display: block;
}

.img-responsive {
    @extend .img-fluid;
    width: 100%;
}

.img-scale-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $color--darkest-grey;
    img {
        display: block;
        transform: scale(1.005);
        transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
        will-change: transform;
    }
    a:hover & img, a:focus & img, & a:hover img, & a:focus img, &:hover img {
        transform: scale(1.05);
        transition-duration: 500ms;
    }
}

.img-loader-icon {
    display: block;
    width: $img-loader-icon--width;
    height: $img-loader-icon--height;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin: calc(-0.5 * #{$img-loader-icon--height}) 0 0 calc(-0.5 * #{$img-loader-icon--height});
    color: white;
    background: $img-loader-icon--background;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
    animation: img-loader-spin 4s 1 linear;
    transition: opacity 500ms linear;
    transition-delay: 0s;
    .lazyloading + & {
        opacity: 1;
        transition-delay: 500ms;
        animation-iteration-count: infinite;
    }
}

// fade in when lazy loaded

.lazy-fade-in:not(.hunt-watch) {
    opacity: 0;
    &.lazyloaded {
        opacity: 1;
        transition: opacity 1s linear, transform 300ms ease-in-out;
    }
}

.lazy-scale-fade-in:not(.hunt-watch) {
    opacity: 0;
    transform: scale(1.2);
    &.lazyloaded {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s linear, transform 1s ease-out;
    }
}

// fade in when lazy loaded + enters viewport

.lazy-fade-in.hunt-watch {
    opacity: 0;
    &.lazyloaded.hunt-in {
        opacity: 1;
        transition: opacity 1s linear, transform 300ms ease-in-out;
    }
}

.lazy-scale-fade-in.hunt-watch {
    opacity: 0;
    transform: scale(1.2);
    &.lazyloaded.hunt-in {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s linear, transform 1s ease-out;
    }
}
