
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: var(--theme--color--emphasis);
    text-transform: uppercase;
    margin-bottom: max(1rem, 0.5em);
    &:before {
        content: '';
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    strong, b {
        font-family: $font-heading-strong;
    }
}
h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    @include non-retina-font-smoothing-antialiased;
}

.alt-heading-font {
    font-family: $font-alt-heading;
    strong, b {
        font-family: $font-alt-heading;
    }
}

strong, b {
    font-family: $font-strong;
    font-weight: normal;
    @include non-retina-font-smoothing-antialiased;
}

.non-breaking {
    white-space: nowrap;
}

.theme-icon {
    color: var(--theme--decor--color);
}

.muted {
    color: var(--theme--color--subtle);
}

.lower-i {
    text-transform: lowercase !important;
}

.instagram-logo {
    --height: 1.1em;
    display: inline-block;
    width: calc(var(--height) * 3.525);
    height: var(--height);
    background: url('../../img/instagram-logo.svg') no-repeat;
    background-size: 100%;
    vertical-align: middle;
    transform: translateY(2%);
}

// icon styling shims
.tripadvisor-icon, .tiktok-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.number-roundel {
    position: relative;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.2em;
    height: 1.2em;
    color: var(--theme--background-color);
    font-family: $font-alt-heading;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    font-size: 0.8em;
    vertical-align: baseline;
    transform: translateY(-0.15em);
    @include non-retina-font-smoothing-antialiased;
    &:before {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--theme--decor--color);
        transform: translateY(-0.08em);
    }
}
