a, .btn, button {
    svg.fa-arrow-right, svg.fa-arrow-left {
        will-change: transform;
        transition: transform 200ms ease-in-out;
    }
    svg.fa-arrow-right {
        margin-left: 0.5em;
    }
    svg.fa-arrow-left {
        margin-right: 0.5em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg.fa-arrow-right {
                transform: translate3d(0.3em,0,0);
            }
            svg.fa-arrow-left {
                transform: translate3d(-0.3em,0,0);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn:not(.btn-theme) {
    &.btn-secondary:not(.btn-link):not(.btn-outline) {
        color: $white;
    }
    &.btn-link {
        background-color: transparent;
        border-color: transparent;
    }
}

.btn:before {
    content: '';
    display: inline-block;
    height: 16px;
}

.btn-theme {
    font-family: $font-alt-heading;
    text-transform: uppercase;
    line-height: 1;
    @include non-retina-font-smoothing-antialiased;
    // fix vertical alignment for 'United' font
    font-size: 16px;
    padding: $btn-theme--padding-top $btn-theme--padding-x $btn-theme--padding-bottom;
    color: var(--theme--link--color);
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus, a:hover &, a:focus & {
            color: var(--theme--link--color--hover);
        }
    }
    &:not(.btn-link) {
        background-color: var(--theme--btn--bg-color);
        border-color: var(--theme--btn--border-color);
        color: var(--theme--btn--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus, a:hover &, a:focus & {
                background-color: var(--theme--btn--bg-color--hover);
                border-color: var(--theme--btn--border-color--hover);
                color: var(--theme--btn--color--hover);
            }
        }
        &.btn-primary {
            background-color: var(--theme--btn--primary--bg-color);
            border-color: var(--theme--btn--primary--border-color);
            color: var(--theme--btn--primary--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    border-color: var(--theme--btn--primary--border-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                }
            }
        }
        &.btn-outline, &.btn-outline-secondary {
            border-color: var(--theme--btn--border-color);
            background-color: transparent;
            color: var(--theme--btn--bg-color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--bg-color--hover);
                    border-color: var(--theme--btn--border-color--hover);
                    color: var(--theme--btn--color--hover);
                }
            }
        }
        &.btn-outline-primary {
            background-color: transparent;
            border-color: var(--theme--btn--primary--border-color);
            color: var(--theme--btn--primary--bg-color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    border-color: var(--theme--btn--primary--border-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                }
            }
        }
    }
    &.btn-link {
        background-color: transparent;
        border-color: transparent;
        &.btn-primary {
            color: var(--theme--link--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    color: var(--theme--link--color--hover);
                }
            }
        }
    }
    &.btn-lg {
        padding: $btn-theme--lg--padding-top $btn-theme--lg--padding-x $btn-theme--lg--padding-bottom;
    }
    &.btn-sm {
        font-size: 14px;
        padding: $btn-theme--sm--padding-top $btn-theme--sm--padding-x $btn-theme--sm--padding-bottom;
    }
}
