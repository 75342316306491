@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}

// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$dark};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$dark};
    // for minor text, helper text etc
    --theme--color--subtle: #{$color--mid-grey};
    // links
    --theme--link--color: #{$link-color};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$link-hover-color};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$primary};
    --theme--rule--color: #{$color--lighter-grey};
    // button - standard
    --theme--btn--bg-color: #{$secondary};
    --theme--btn--border-color: #{$secondary};
    --theme--btn--color: #{white};
    --theme--btn--bg-color--hover: #{$link-hover-color};
    --theme--btn--border-color--hover: #{$link-hover-color};
    --theme--btn--color--hover: #{white};
    // button - primary
    --theme--btn--primary--bg-color: #{$link-color};
    --theme--btn--primary--border-color: #{$link-color};
    --theme--btn--primary--color: #{white};
    --theme--btn--primary--bg-color--hover: #{$link-hover-color};
    --theme--btn--primary--border-color--hover: #{$link-hover-color};
    --theme--btn--primary--color--hover: #{white};
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$light};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{white};
    // for minor text, helper text etc
    --theme--color--subtle: #{$color--lighter-grey};
    // links
    --theme--link--color: white;
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: white;
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: white;
    --theme--rule--color: #{$color--dark-grey};
    // button - standard
    --theme--btn--bg-color: #{$secondary};
    --theme--btn--border-color: #{$secondary};
    --theme--btn--color: #{white};
    --theme--btn--bg-color--hover: #{$link-hover-color};
    --theme--btn--border-color--hover: #{$link-hover-color};
    --theme--btn--color--hover: #{white};
    // button - primary
    --theme--btn--primary--bg-color: #{$link-color};
    --theme--btn--primary--border-color: #{$link-color};
    --theme--btn--primary--color: #{white};
    --theme--btn--primary--bg-color--hover: #{$link-hover-color};
    --theme--btn--primary--border-color--hover: #{$link-hover-color};
    --theme--btn--primary--color--hover: #{white};
}
