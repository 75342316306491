.tripadvisor-svg-icon {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
    width: 1em;
}

#main-footer {

    box-shadow: 0 0 8px rgba(black, 0.4);

    #main-footer--primary-links {
        --theme--color--emphasis: #{$color--dark-grey};
        --theme--link--color: #{$color--dark-grey};
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: start;
        }
    }

    #main-footer--social-links ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        li {
            margin: 0;
            width: 45px;
            flex: 0 0 auto;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                font-size: 24px;
                background-color: $color--lightest-grey;
                color: $brand--red;
                border-radius: 5px;
                transition: all 200ms linear;
                &:hover, &:focus {
                    background-color: var(--theme--link--color--hover);
                    color: white;
                    text-decoration: none;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }

    #main-footer--logo {
        text-align: center;
        svg {
            width: 100%;
            height: auto;
            max-width: 160px;
            .flyer {
                color: $brand--red;
            }
            .text {
                color: $color--darkest-grey;
            }
        }
        @include media-breakpoint-up(lg) {
            text-align: end;
        }
    }

    .main-footer--logo-and-text {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
            flex: 0 0 auto;
        }
        p {
            flex: 1 1 auto;
            margin: 0;
            text-align: start;
        }
        @include media-breakpoint-up(lg) {
            &.logo-right {
                img {
                    order: 2;
                }
                p {
                    order: 1;
                    text-align: end;
                }
            }
        }
    }

}
