﻿.pay-buttons-block {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: space-evenly;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .pay-buttons-block--item {
        margin-bottom: 10px;
        z-index: 1;

        &.apple-hide {
            display: none;
        }

        &.apple-show {
            display: inline-block !important;
            margin-bottom: 6px;
        }

        button {
            height: 46px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        #googlepay_button_container {
            height: 46px;
            width: 150px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .zoid-outlet {
            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }
}

apple-pay-button {

    --apple-pay-button-width: 150px;
    --apple-pay-button-height: 46px;
    --apple-pay-button-border-radius: 3px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;

    @include media-breakpoint-down(md) {
        --apple-pay-button-width: 100%;
    }
}
