.no-border {
    border-color: transparent;
    background-clip: border-box;
}

.form-number-control {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    button {
        user-select: none;
    }
}

.form-dropdown-control {
    width: 100%;
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 0;
    color: #2d2926
}

.form-dropdown-list {
    overflow-y: scroll;
    max-height: 500px;
}

#selected_country--dropdown-button {
    width: 100%;
    text-align: left;
    color: #2d2926
}
